.App {
  text-align: center;
}

.wrapper {
  width: 100%;
}

table.fixed {
  table-layout: fixed;
}

table.fixed td {
  overflow: hidden;
}
